export default response => {
  if (response.status === 0) {
    // Some browsers return HTTP Status 0 when using non-http protocol
    // e.g. 'file://' or 'data://'. Handle as success.
    console.warn('THREE.FileLoader: HTTP Status 0 received.');
    return response;
  }
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};
