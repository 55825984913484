import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import * as cameraConstants from './constants/camera';
import Camera from './camera';

class Controls {
  constructor() {
    this._controls = null;
  }

  isInitialized = () => !!this._controls;

  init = threejsContainer => {
    try {
      if (!Camera.isInitialized()) return false;

      this._controls = new OrbitControls(Camera.getCamera(), threejsContainer);
      this._controls.target.set(0, -0.2, 0);
      // this._controls.enablePan = false;
      // this._controls.enableZoom = false;
      this._controls.maxPolarAngle = Math.PI / 2;
      this._controls.update();

      this._controls.minDistance = 30;
      this._controls.maxDistance = 90;

      this._controls.panSpeed /= cameraConstants.initialZoom;

      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getControls = () => this._controls;
}

const controls = new Controls();

export default controls;
