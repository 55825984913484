import Camera from './camera';
import Controls from './controls';
import Renderer from './renderer';
import Scene from './scene';

class ThreeApp {
  constructor() {
    this._scene = null;
    this._renderer = null;
  }

  isInitialized = () =>
    Camera.isInitialized() &&
    Controls.isInitialized() &&
    Renderer.isInitialized() &&
    Scene.isInitialized();

  init = (offsetWidth, offsetHeight, threejsContainer) => {
    Camera.init(offsetWidth, offsetHeight);
    Controls.init(threejsContainer);
    Renderer.init(offsetWidth, offsetHeight);
    Scene.init();
  };

  onResize = (newOffsetWidth, newOffsetHeight) => {
    Camera.updateAcpect(newOffsetWidth, newOffsetHeight);
    Renderer.setSize(newOffsetWidth, newOffsetHeight);
  };

  getRendererDomElement = Renderer.getDomElement;

  render = Renderer.render;

  addModel = Scene.addModel;

  moveModel = Scene.moveModel;

  deleteModel = Scene.deleteModel;

  highlightModel = Scene.highlightModel;

  defaultModelColor = Scene.defaultModelColor;

  zoom = Camera.zoom;

  unzoom = Camera.unzoom;

  rotate = Scene.rotate;

  rotateReverse = Scene.rotateReverse;

  rotateCamera = () => {
    Camera.rotate();
    Controls.getControls().update();
  };
}

const threeApp = new ThreeApp();

export default threeApp;
