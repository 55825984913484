import * as THREE from 'three';

import * as cameraConstants from './constants/camera';
import {RIGHT, LEFT} from "./constants/camera";

class Camera {
  constructor() {
    this._camera = null;
    this._rotateDirection = null;
    this._actualZPosition = 0;
  }

  isInitialized = () => !!this._camera;

  init = (offsetWidth, offsetHeight) => {
    try {
      if (this._camera) return false;
      this._camera = new THREE.PerspectiveCamera(
        50,
        offsetWidth / offsetHeight,
        0.05,
        100
      );

      this.setPosition();

      this._camera.zoom = cameraConstants.initialZoom;
      this._camera.updateProjectionMatrix();
      this._rotateDirection = LEFT;
      this._actualZPosition = 0;

      return true;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  // renderer usage => renderer.render(camera, scene)
  getCamera = () => this._camera;

  setPosition = (position = cameraConstants.cameraDefaulPosition) => {
    if (!this.isInitialized()) {
      return false;
    }
    if (position.x) this._camera.position.x = position.x;
    if (position.y) this._camera.position.x = position.y;
    if (position.z) this._camera.position.x = position.z;
    return true;
  };

  updateAcpect = (offsetWidth, offsetHeight) => {
    this._camera.aspect = offsetWidth / offsetHeight;
    this._camera.updateProjectionMatrix();
  };

  zoom = () => {
    this._camera.zoom = this._camera.zoom + cameraConstants.zoomGraduation;
    this._camera.updateProjectionMatrix();
  };

  unzoom = () => {
    this._camera.zoom = this._camera.zoom - cameraConstants.zoomGraduation;
    this._camera.updateProjectionMatrix();
  };

  rotate = () => {
    console.log(this._camera.up);
    if (this._camera.up.z <= 0 || this._camera.up.z >= cameraConstants.zPositions.length - 1) {
      this._toggleDirection();
    }
    this._actualZPosition += this._rotateDirection;
    this._camera.up.set(0,2, cameraConstants.zPositions[this._actualZPosition]);
  };

  _toggleDirection = () => {
    this._rotateDirection = this._rotateDirection === RIGHT ? LEFT : RIGHT;
  }
}

const camera = new Camera();

export default camera;
