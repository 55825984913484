import React from 'react';
import PropTypes from 'prop-types';
import logo from './koala42.svg';

import styles from './CopyRight.module.scss';

const CopyRight = ({ name }) => (
  <div className={styles.copyRight}>
    <div className={styles.legal}>
      © {new Date().getFullYear()} {name}
    </div>
    <div className={styles.legal}>All Rights Reserved</div>
    <div className={styles.legal}>
      <span>Crafted by</span>
      <a href="https://koala42.com" className={styles.link}>
        <img src={logo} alt="" className={styles.logo} />
        koala42.com
      </a>
    </div>
  </div>
);

CopyRight.propTypes = {
  name: PropTypes.string
};

CopyRight.defaultProps = {
  name: ''
};

export default CopyRight;
