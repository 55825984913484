import * as R from 'ramda';

export default (modelConfig, rootModelPositions) => {
  const attachablePositions = R.compose(
    R.map(position => position.position_name),
    R.path(['attachable_to'])
  )(modelConfig);
  return R.filter(rootPosition =>
    R.includes(rootPosition, attachablePositions)
  )(rootModelPositions);
};
