import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4bc4c4',
      main: '#1eb6b6',
      dark: '#157f7f',
      contrastText: '#000000'
    },
    secondary: {
      main: '#f44336'
    }
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  }
});

export default theme;
