import * as R from 'ramda';
import {
  ADD_MODEL,
  DELETE_MODEL,
  SELECT_MODEL,
  UNSELECT_MODEL,
  DELETE_CLUTCH,
  ADD_CLUTCH,
  DELETE_SCREW,
  ADD_SCREW
} from '../constants/ActionTypes';
import getDefaultPositions from '../common/utils/getDefaultPositionsFromModelConfig';
import getPositionConfigFromModelConfig from '../common/utils/getPositionConfigFromModelConfig';
import getRelativePositions from '../common/utils/getRelativePositions';
import getModelConfigFromCommonState from '../common/utils/getModelConfigFromCommonState';
import * as fetchingStates from '../constants/fetchingStates';

export const addModel = (modelName, options) => (dispatch, getState) => {
  const { common: commonState } = getState();

  /*
    TODO: try only few times, then show loading failiture???
  */
  if (commonState.configFetchingState !== fetchingStates.FETCHED) {
    setTimeout(() => dispatch(addModel(modelName, options)), 500);
    return;
  }

  const modelConfig = getModelConfigFromCommonState(modelName)(commonState);

  dispatch({
    type: ADD_MODEL,
    payload: {
      modelName,
      options: {
        absolutePositions: [{ x: 0, y: 0, z: 0, rx: 0, ry: 0, rz: 0 }],
        availablePositions: getDefaultPositions(modelConfig),
        displayName: modelConfig.display_name
      }
    }
  });
};

export const deleteModel = uuid => ({
  type: DELETE_MODEL,
  payload: {
    uuid
  }
});

export const deleteClutch = uuid => ({
  type: DELETE_CLUTCH,
  payload: {
    uuid
  }
});

export const addClutch = (
  clutchName,
  position,
  rootModel,
  attachedModelUuid
) => (dispatch, getState) => {
  const { common: commonState } = getState();

  const clutchConfig = getModelConfigFromCommonState(clutchName)(commonState);
  const clutchPositionConfig = getPositionConfigFromModelConfig(
    position,
    clutchConfig
  );

  if (!clutchPositionConfig) {
    console.warn(`no config for clutch '${clutchName}'`);
    return;
  }

  getRelativePositions(
    rootModel.position,
    rootModel.rotation,
    clutchPositionConfig
  ).forEach(clutchPosition => {
    dispatch({
      type: ADD_CLUTCH,
      payload: {
        modelName: clutchName,
        options: {
          relativePositions: [clutchPosition],
          displayName: clutchConfig.display_name
        }
      },
      connectedModelsUuids: [rootModel.uuid, attachedModelUuid]
    });
  });
};

export const deleteScrew = uuid => ({
  type: DELETE_SCREW,
  payload: {
    uuid
  }
});

export const addScrews = (position, rootModel, screwName = 'sroub_maly') => (
  dispatch,
  getState
) => {
  const { common: commonState } = getState();

  const screwConfig = getModelConfigFromCommonState(screwName)(commonState);
  const screwPositionConfig = getPositionConfigFromModelConfig(
    position,
    screwConfig
  );

  if (!screwPositionConfig) {
    console.warn(`no config for screw '${screwName}'`);
    return;
  }

  getRelativePositions(
    rootModel.position,
    rootModel.rotation,
    screwPositionConfig
  ).forEach(screwPosition => {
    dispatch({
      type: ADD_SCREW,
      payload: {
        modelName: screwName,
        options: {
          relativePositions: [screwPosition],
          displayName: screwConfig.display_name
        }
      },
      connectedModelsUuids: [rootModel.uuid]
    });
  });
};

export const initPlayground = () => (dispatch, getState) => {
  // TODO: modify initial panel
  const playgroundState = getState().playground;
  if (R.isEmpty(playgroundState.models)) {
    dispatch(addModel('panel'));
  }
};

export const selectModel = uuid => ({
  type: SELECT_MODEL,
  payload: {
    uuid
  }
});

export const unselectModel = uuid => ({
  type: UNSELECT_MODEL,
  payload: {
    uuid
  }
});
