import {
  LOAD_CONFIG_REQUEST,
  LOAD_CONFIG_SUCCESS,
  LOAD_CONFIG_ERROR
} from '../constants/ActionTypes';
import * as fetchingStates from '../constants/fetchingStates';

const defaultState = {
  configFetchingState: fetchingStates.UNFETCHED,
  config: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_CONFIG_REQUEST: {
      return {
        ...state,
        configFetchingState: fetchingStates.FETCHING,
        config: null
      };
    }
    case LOAD_CONFIG_SUCCESS: {
      return {
        ...state,
        configFetchingState: fetchingStates.FETCHED,
        config: action.payload.config
      };
    }
    case LOAD_CONFIG_ERROR: {
      return {
        ...state,
        configFetchingState: fetchingStates.ERROR,
        config: null
      };
    }
    default:
      return state;
  }
};
