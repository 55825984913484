import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import createStore from '../../store';
import theme from './theme';
import AppLayout from '../../components/AppLayout';
import PlaygroundPage from '../../components/PlaygroundPage';
import ComponentList from '../ComponentList';
import NotFoundPage from '../../components/NotFoundPage';

import './App.module.scss';

const store = createStore();

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <AppLayout>
          <Switch>
            <Route exact path="/" component={PlaygroundPage} />
            <Route exact path="/components-list" component={ComponentList} />
            <Route component={NotFoundPage} />
          </Switch>
        </AppLayout>
      </Router>
    </Provider>
  </MuiThemeProvider>
);

export default App;
