import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';

import LinearProgress from '@material-ui/core/LinearProgress';
import Page from '../Page';
import Playground from '../../containers/Playground';
import Models from '../../containers/Models';
import Controls from '../../containers/Controls';
import { loadConfig as loadConfigAction } from '../../actions/common';
import { FETCHED } from '../../constants/fetchingStates';

import styles from './PlaygroundPage.module.scss';

class PlaygroundPage extends Component {
  componentDidMount() {
    const { loadConfig } = this.props;
    loadConfig();
  }

  render() {
    const { configLoadingState } = this.props;

    if (configLoadingState !== FETCHED)
      return (
        <div className={styles.progressBar}>
          <LinearProgress />
        </div>
      );
    return (
      <Page noPadding>
        <div className={styles.PlaygroundWrapper}>
          <div className={styles.Canvas}>
            <Playground />
          </div>
          <div className={styles.Models}>
            <Controls />
            <Models />
          </div>
        </div>
      </Page>
    );
  }
}

PlaygroundPage.propTypes = {
  configLoadingState: PropTypes.string.isRequired,
  loadConfig: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  configLoadingState: state.common.configFetchingState
});

const mapDispatchToProps = dispatch => ({
  loadConfig: bindActionCreators(loadConfigAction, dispatch)
});

const connector = R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(PlaygroundPage);
