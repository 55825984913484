import {
  LOAD_CONFIG_REQUEST,
  LOAD_CONFIG_SUCCESS,
  LOAD_CONFIG_ERROR
} from '../constants/ActionTypes';

import handleErrors from '../common/utils/handleFetchErrors';
import * as Urls from '../constants/Urls';
import * as fetchingStates from '../constants/fetchingStates';

const loadConfigRequest = () => ({
  type: LOAD_CONFIG_REQUEST
});

const loadConfigSuccess = config => ({
  type: LOAD_CONFIG_SUCCESS,
  payload: {
    config
  }
});

const loadConfigFailure = error => ({
  type: LOAD_CONFIG_ERROR,
  payload: {
    errorMessage: error.message
  }
});

// eslint-disable-next-line
export const loadConfig = () => (dispatch, getState) => {
  if (getState().common.configFetchingState !== fetchingStates.UNFETCHED) {
    return;
  }

  dispatch(loadConfigRequest());
  // eslint-disable-next-line
  fetchPollyfil(Urls.CONFIG_URL)
    .then(handleErrors)
    .then(res => res.json())
    .then(config => {
      dispatch(loadConfigSuccess(config));
    })
    .catch(error => dispatch(loadConfigFailure(error)));
};

// eslint-disable-next-line
function fetchPollyfil(url) {
  return new Promise(function(resolve, reject) {
    // eslint-disable-next-line
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      // eslint-disable-next-line
      resolve(new Response(xhr.responseText, { status: xhr.status }));
    };
    xhr.onerror = function() {
      reject(new TypeError('Local request failed'));
    };
    xhr.open('GET', url);
    xhr.send(null);
  });
}
