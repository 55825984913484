import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import mainReducer from './reducers';
import threeMiddleware from './middlewares/three';

export default () => {
  return createStore(
    mainReducer,
    composeWithDevTools(applyMiddleware(thunk, threeMiddleware))
  );
};
