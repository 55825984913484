import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Vector3, Euler } from 'three';
import ComponentsListPage from '../../components/ComponentListTable';

const ComponentList = ({ models, clutches, screws }) => {
  return (
    <ComponentsListPage models={models} clutches={clutches} screws={screws} />
  );
};

ComponentList.propTypes = {
  models: PropTypes.arrayOf(
    PropTypes.shape({
      modelName: PropTypes.string,
      position: PropTypes.objectOf(Vector3),
      positions: PropTypes.arrayOf(PropTypes.string),
      rotation: PropTypes.objectOf(Euler),
      uuid: PropTypes.string
    })
  ).isRequired,
  clutches: PropTypes.arrayOf(
    PropTypes.shape({
      modelName: PropTypes.string,
      position: PropTypes.objectOf(Vector3),
      positions: PropTypes.arrayOf(PropTypes.string),
      rotation: PropTypes.objectOf(Euler),
      uuid: PropTypes.string
    })
  ).isRequired,
  screws: PropTypes.arrayOf(
    PropTypes.shape({
      modelName: PropTypes.string,
      position: PropTypes.objectOf(Vector3),
      positions: PropTypes.arrayOf(PropTypes.string),
      rotation: PropTypes.objectOf(Euler),
      uuid: PropTypes.string
    })
  ).isRequired
};

const mapStateToProps = state => ({
  models: state.playground.models,
  clutches: state.playground.clutches,
  screws: state.playground.screws
});

const connector = R.compose(connect(mapStateToProps));

export default connector(ComponentList);
