import * as R from 'ramda';
import disablePositions from './disablePositions';

export default (defaultPositions, newConnections) => {
  let newPositions = [...defaultPositions];

  R.forEach(connection => {
    const { disabledPositions, disableSides } = connection;
    // eslint-disable-next-line
    newPositions = disablePositions(
      disabledPositions,
      disableSides,
      newPositions
    ).newPositions;
  })(newConnections);

  return newPositions;
};
