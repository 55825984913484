import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';

import ControlsLayout from '../../components/ControlsLayout';
import {
  save as saveAction,
  cancel as cancelAction,
  handleDelete as deleteAction,
  nextDeleteModel as nextDeleteModelAction,
  previousDeleteModel as previousDeleteModelAction,
  nextRootModel as nextRootModelAction,
  previousRootModel as previousRootModelAction,
  nextPosition as nextPositionAction,
  previousPosition as previousPositionAction
} from '../../actions/attachedModel';
import { FETCHED } from '../../constants/fetchingStates';
import * as editTypes from '../../constants/EditTypes';

const Controls = ({
  save,
  cancel,
  nextDeleteModel,
  previousDeleteModel,
  nextRootModel,
  previousRootModel,
  nextPosition,
  previousPosition,
  editType,
  attachedModel,
  deleteModel,
  handleDelete,
  rootModels,
  deleteableModels,
  rootModelAttachablePositions,
  fetchingState
}) => (
  <ControlsLayout
    editType={editType}
    controlsIn={attachedModel || deleteModel}
    nextDelete={nextDeleteModel}
    prevDelete={previousDeleteModel}
    nextRoot={nextRootModel}
    prevRoot={previousRootModel}
    nextPosition={nextPosition}
    prevPosition={previousPosition}
    save={save}
    cancel={cancel}
    handleDelete={handleDelete}
    disableRoot={
      (editType === editTypes.ATTACH && R.length(rootModels) <= 1) ||
      (editType === editTypes.DELETE && R.length(deleteableModels) <= 1) ||
      fetchingState !== FETCHED
    }
    disablePosition={
      R.length(rootModelAttachablePositions) <= 1 || fetchingState !== FETCHED
    }
  />
);

Controls.propTypes = {
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  nextDeleteModel: PropTypes.func.isRequired,
  previousDeleteModel: PropTypes.func.isRequired,
  nextRootModel: PropTypes.func.isRequired,
  previousRootModel: PropTypes.func.isRequired,
  nextPosition: PropTypes.func.isRequired,
  previousPosition: PropTypes.func.isRequired,

  editType: PropTypes.string,
  // eslint-disable-next-line
  attachedModel: PropTypes.object,
  // eslint-disable-next-line
  deleteModel: PropTypes.object,
  rootModels: PropTypes.arrayOf(PropTypes.object),
  rootModelAttachablePositions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchingState: PropTypes.string.isRequired
};

Controls.defaultProps = {
  attachedModel: null,
  deleteModel: null
};

const mapStateToProps = state => ({
  editType: state.attachedModel.editType,
  attachedModel: state.attachedModel.attachedModel,
  deleteModel: state.attachedModel.deleteModel,
  rootModels: state.attachedModel.rootModels,
  deleteableModels: state.attachedModel.deleteableModels,
  rootModelAttachablePositions:
    state.attachedModel.rootModelAttachablePositions,
  fetchingState: state.playground.fetchingState
});

const mapDispatchToProps = dispatch => ({
  save: bindActionCreators(saveAction, dispatch),
  cancel: bindActionCreators(cancelAction, dispatch),
  handleDelete: bindActionCreators(deleteAction, dispatch),
  nextDeleteModel: bindActionCreators(nextDeleteModelAction, dispatch),
  previousDeleteModel: bindActionCreators(previousDeleteModelAction, dispatch),
  nextRootModel: bindActionCreators(nextRootModelAction, dispatch),
  previousRootModel: bindActionCreators(previousRootModelAction, dispatch),
  nextPosition: bindActionCreators(nextPositionAction, dispatch),
  previousPosition: bindActionCreators(previousPositionAction, dispatch)
});

const connector = R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(Controls);
