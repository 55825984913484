import * as R from 'ramda';
import {
  SAVE_ATTACHED_MODEL,
  CANCEL_ATTACHED_MODEL,
  DELETE_MODEL,
  SELECT_MODEL,
  UNSELECT_MODEL,
  ADD_MODEL,
  DELETE_CLUTCH,
  ADD_CLUTCH,
  FETCH_MODEL,
  FETCHED_MODEL,
  DELETE_SCREW,
  ADD_SCREW
} from '../constants/ActionTypes';
import * as fetchingStates from '../constants/fetchingStates';
import getDeleteableModelsFromModels from '../common/utils/getDeleteableModelsFromModels';
import enablePositions from '../common/utils/enablePositions';

const defaulState = {
  models: [],
  selectedModels: [],
  clutches: [],
  screws: [],
  fetchingState: fetchingStates.FETCHED,
  fetchingCount: 0
};

export default (state = defaulState, action) => {
  switch (action.type) {
    case FETCH_MODEL:
      return {
        ...state,
        fetchingState: fetchingStates.FETCHING,
        fetchingCount: state.fetchingCount + 1
      };
    case FETCHED_MODEL:
      return {
        ...state,
        fetchingCount:
          state.fetchingCount - 1 <= 0 ? 0 : state.fetchingCount - 1,
        fetchingState:
          state.fetchingCount - 1 <= 0
            ? fetchingStates.FETCHED
            : fetchingStates.FETCHING
      };
    case ADD_MODEL:
    case SAVE_ATTACHED_MODEL: {
      const {
        newRootModel,
        newAttachedModel,
        clutches = [],
        screws = [],
        deleteModel
      } = action.payload;

      const { models, clutches: originClutches, screws: originScrews } = state;

      // add model save
      if (!deleteModel) {
        const newModels = [...models, newAttachedModel];
        if (newRootModel) {
          const rootModelIndex = R.findIndex(
            m => m.uuid === newRootModel.uuid,
            newModels
          );
          newModels[rootModelIndex] = newRootModel;
        }
        return {
          ...state,
          models: newModels,
          clutches: [...originClutches, ...clutches],
          screws: [...originScrews, ...screws]
        };
      }

      // TODO: save newModels

      return state;
    }
    case CANCEL_ATTACHED_MODEL: {
      const { oldClutchesUuids, newClutches } = action.payload;
      if (oldClutchesUuids && newClutches) {
        console.log('update clutches');
        const newClutchesState = [
          ...R.filter(
            clutch => !R.includes(clutch.uuid, oldClutchesUuids),
            state.clutches
          ),
          ...newClutches
        ];

        return {
          ...state,
          clutches: newClutchesState
        };
      }
      return state;
    }
    case DELETE_MODEL: {
      const { models } = state;
      const { uuid: deleteModelUuid } = action.payload;
      const deleteModel = R.find(R.propEq('uuid', deleteModelUuid), models);
      const deleteConnection = R.pathOr({}, ['connections', 0], deleteModel);
      if (deleteModel) {
        return {
          ...state,
          models: R.compose(
            R.map(model => {
              if (model.uuid === deleteConnection.connectedModelUuid) {
                const newConnections = R.filter(
                  con => con.connectedModelUuid !== deleteModel.uuid,
                  model.connections
                );
                return {
                  ...model,
                  connections: newConnections,
                  positions: enablePositions(
                    model.defaultPositions,
                    newConnections
                  )
                };
              }
              return model;
            }),
            R.filter(model => model.uuid !== deleteModel.uuid)
          )(models),
          selectedModels: R.filter(
            selectedModel => selectedModel.uuid !== action.payload.uuid,
            state.selectedModels
          )
        };
      }

      return state;
    }
    case SELECT_MODEL: {
      return {
        ...state,
        selectedModels: [...state.selectedModels, action.payload.model]
      };
    }
    case UNSELECT_MODEL: {
      return {
        ...state,
        selectedModels: R.filter(
          selectedModel => selectedModel.uuid !== action.payload.uuid,
          state.selectedModels
        )
      };
    }
    case DELETE_CLUTCH: {
      return {
        ...state,
        clutches: R.filter(clutch => clutch.uuid !== action.payload.uuid)(
          state.clutches
        ),
        selectedModels: R.filter(
          selectedModel => selectedModel.uuid !== action.payload.uuid,
          state.selectedModels
        )
      };
    }
    case ADD_CLUTCH: {
      return {
        ...state,
        clutches: [...state.clutches, action.payload.newAttachedModel]
      };
    }
    case DELETE_SCREW: {
      return {
        ...state,
        screws: R.filter(screw => screw.uuid !== action.payload.uuid)(
          state.screws
        ),
        selectedModels: R.filter(
          selectedModel => selectedModel.uuid !== action.payload.uuid,
          state.selectedModels
        )
      };
    }
    case ADD_SCREW: {
      return {
        ...state,
        screws: [...state.screws, action.payload.newAttachedModel]
      };
    }
    default:
      return state;
  }
};

export const hasDeletableModels = state => {
  const editableModels = getDeleteableModelsFromModels(state.models || []);
  return !R.isEmpty(editableModels);
};
