/* eslint-disable no-param-reassign */
import * as THREE from 'three';
import * as R from 'ramda';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { SceneUtils } from 'three/examples/jsm/utils/SceneUtils';

import { modelsFolder } from '../utils/getModelUrl';
import * as sceneConstants from './constants/scene';

class Scene {
  constructor() {
    this._scene = null;
  }

  isInitialized = () => !!this._scene;

  init = () => {
    try {
      if (this._scene) return false;
      this._scene = new THREE.Scene();

      this._initLights();
      this._initBackground();

      const pivot = new THREE.Scene();
      pivot.updateMatrixWorld();
      this._pivotUuid = pivot.uuid;
      this._scene.add(pivot);

      const movedPivot = new THREE.Scene();
      movedPivot.updateMatrixWorld();
      this._movedPivotUuid = movedPivot.uuid;
      this._scene.add(movedPivot);

      /*
      const geo = new THREE.PlaneBufferGeometry(2000, 2000, 8, 8);
      const mat = new THREE.MeshBasicMaterial({
        color: 0xe5e5e5,
        side: THREE.DoubleSide
      });
      const plane = new THREE.Mesh(geo, mat);
      plane.rotateX(-Math.PI / 2);
      this._scene.add(plane);
      */

      return true;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  _initLights = () => {
    const light = new THREE.AmbientLight(0xffffff, 0.8);
    this._scene.add(light);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    this._scene.add(directionalLight);
  };

  _initBackground = () => {
    const color = new THREE.Color(0xffffff);
    this._scene.background = color;
  };

  getScene = () => this._scene;

  rotate = () => {
    this._scene.rotateY(sceneConstants.rotationGraduation);
  };

  rotateReverse = () => {
    this._scene.rotateY(-sceneConstants.rotationGraduation);
  };

  _getModelByUuid = uuid =>
    R.find(child => child.uuid === uuid, this._scene.children);

  _getPivot = () => {
    return this._getModelByUuid(this._pivotUuid);
  };

  _getMovedPivot = () => {
    return this._getModelByUuid(this._movedPivotUuid);
  };

  _setModelVisibility = (uuid, visibility) => {
    switch (visibility) {
      case 'highlight': {
        this.highlightModel(uuid);
        return;
      }
      case 'preview': {
        this.previewModel(uuid);
        return;
      }
      default:
        this.defaultModelColor(uuid);
    }
  };

  addModel = (modelName, options = {}, onSuccess) => {
    if (!this.isInitialized()) return;

    const {
      relativePositions = [],
      absolutePositions = [],
      visibility = 'default',
      availablePositions = []
    } = options;

    R.forEach(relativePosition =>
      this._loadModel(modelName, onSuccess, {
        visibility,
        availablePositions,
        relativePosition
      })
    )(relativePositions);
    R.forEach(absolutePosition =>
      this._loadModel(modelName, onSuccess, {
        visibility,
        availablePositions,
        absolutePosition
      })
    )(absolutePositions);
  };

  _loadModel = (modelName, onSuccess, options) => {
    const {
      relativePosition,
      visibility,
      absolutePosition,
      availablePositions
    } = options;

    const loader = new GLTFLoader().setPath(modelsFolder);
    loader.load(
      `${modelName}.gltf`,
      ({ scene }) => {
        if (relativePosition) this._addModelRelatively(scene, relativePosition);
        if (absolutePosition) this._addModelAbsolutly(scene, absolutePosition);
        this._setModelVisibility(scene.uuid, visibility);

        onSuccess({
          uuid: scene.uuid,
          modelName,
          position: scene.position,
          rotation: scene.rotation,
          positions: availablePositions,
          defaultPositions: availablePositions
        });
      },
      undefined,
      error => {
        console.error(error);
      }
    );
  };

  _addModelRelatively = (model, relativeMove) => {
    const { rootModel, relativePosition } = relativeMove;
    const {
      position: rootModelPosition,
      rotation: rootModelRotation
    } = rootModel;
    const pivot = this._getPivot();

    pivot.position.set(
      rootModelPosition.x || 0,
      rootModelPosition.y || 0,
      rootModelPosition.z || 0
    );
    pivot.rotation.set(
      rootModelRotation.x || 0,
      rootModelRotation.y || 0,
      rootModelRotation.z || 0
    );
    pivot.updateMatrixWorld();

    model.position.set(
      relativePosition.x || 0,
      relativePosition.y || 0,
      relativePosition.z || 0
    );
    model.rotation.set(
      THREE.Math.degToRad(relativePosition.rx) || 0,
      THREE.Math.degToRad(relativePosition.ry) || 0,
      THREE.Math.degToRad(relativePosition.rz) || 0
    );
    model.updateMatrixWorld();

    pivot.add(model);
    SceneUtils.detach(model, pivot, this._scene);
  };

  _addModelAbsolutly = (model, position = {}) => {
    model.position.x = position.x || 0;
    model.position.y = position.y || 0;
    model.position.z = position.z || 0;
    model.rotation.x = THREE.Math.degToRad(position.rx) || 0;
    model.rotation.y = THREE.Math.degToRad(position.ry) || 0;
    model.rotation.z = THREE.Math.degToRad(position.rz) || 0;

    this.addModelToScene(model);
  };

  addModelToScene = model => {
    this._scene.add(model);
  };

  moveModel = (uuid, options) => {
    const { relativePosition, absolutePosition, addToMoveModels } = options;

    if (relativePosition)
      this._moveModelRelatively(uuid, relativePosition, addToMoveModels);
    if (absolutePosition) this._moveModelAbsolutely(uuid, absolutePosition);

    const model = this._getModelByUuid(uuid);
    const attachedModel = {
      uuid: model.uuid,
      position: model.position,
      rotation: model.rotation
    };
    return attachedModel;
  };

  _moveModelRelatively = (modelUuid, relativeMove, addToMoveModels = []) => {
    const { rootModel, relativePosition } = relativeMove;
    const {
      position: rootModelPosition,
      rotation: rootModelRotation
    } = rootModel;

    const pivot = this._getPivot();
    const movedPivot = this._getMovedPivot();
    const model = this._getModelByUuid(modelUuid);

    pivot.position.set(
      rootModelPosition.x || 0,
      rootModelPosition.y || 0,
      rootModelPosition.z || 0
    );
    pivot.rotation.set(
      rootModelRotation.x || 0,
      rootModelRotation.y || 0,
      rootModelRotation.z || 0
    );
    pivot.updateMatrixWorld();
    model.updateMatrixWorld();

    SceneUtils.detach(movedPivot, pivot, this._scene);

    movedPivot.position.set(
      relativePosition.x || 0,
      relativePosition.y || 0,
      relativePosition.z || 0
    );
    movedPivot.rotation.set(
      THREE.Math.degToRad(relativePosition.rx) || 0,
      THREE.Math.degToRad(relativePosition.ry) || 0,
      THREE.Math.degToRad(relativePosition.rz) || 0
    );
    movedPivot.updateMatrixWorld();
    pivot.add(movedPivot);
    movedPivot.updateMatrixWorld();
    SceneUtils.detach(movedPivot, pivot, this._scene);

    const moveModels = [
      { model, relativePosition: { x: 0, y: 0, z: 0, rx: 0, ry: 0, rz: 0 } },
      ...R.map(
        addModel => ({
          model: this._getModelByUuid(addModel.uuid),
          relativePosition: addModel.relativePosition
        }),
        addToMoveModels
      )
    ];
    R.forEach(addModel => {
      SceneUtils.detach(addModel.model, movedPivot, this._scene);

      addModel.model.position.set(
        addModel.relativePosition.x || 0,
        addModel.relativePosition.y || 0,
        addModel.relativePosition.z || 0
      );
      addModel.model.rotation.set(
        THREE.Math.degToRad(addModel.relativePosition.rx) || 0,
        THREE.Math.degToRad(addModel.relativePosition.ry) || 0,
        THREE.Math.degToRad(addModel.relativePosition.rz) || 0
      );
      addModel.model.updateMatrixWorld();
      movedPivot.add(addModel.model);

      SceneUtils.detach(addModel.model, movedPivot, this._scene);
    }, moveModels);
  };

  _moveModelAbsolutely = (modelUuid, position = {}) => {
    const model = this._getModelByUuid(modelUuid);
    console.log('model', model);
    console.log('absolutePosition', position);
    model.position.x = R.has('x')(position) ? position.x : model.position.x;
    model.position.y = R.has('y')(position) ? position.y : model.position.y;
    model.position.z = R.has('z')(position) ? position.z : model.position.z;
    model.rotation.x = R.has('rx')(position) ? position.rx : model.position.rx;
    model.rotation.y = R.has('ry')(position) ? position.ry : model.position.ry;
    model.rotation.z = R.has('rz')(position) ? position.rz : model.position.rz;
  };

  deleteModel = uuid => {
    const modelToDelete = R.find(
      child => child.uuid === uuid,
      this._scene.children
    );
    this._scene.remove(modelToDelete);
  };

  highlightModel = uuid => {
    const model = this._getModelByUuid(uuid);
    model.traverse(child => {
      if (child.material) {
        child.material.opacity = 0.8;
        child.material.transparent = true;
      }
    });

    return {
      uuid
    };
  };

  defaultModelColor = uuid => {
    const model = this._getModelByUuid(uuid);
    model.traverse(child => {
      if (child.material) {
        child.material.opacity = 1;
        child.material.transparent = true;
      }
    });

    return {
      uuid
    };
  };

  previewModel = uuid => {
    const model = this._getModelByUuid(uuid);
    model.traverse(child => {
      if (child.material) {
        child.material.opacity = 0.8;
        child.material.transparent = true;
      }
    });

    return {
      uuid
    };
  };
}

const scene = new Scene();

export default scene;
