import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { LinearProgress, Fab, Zoom } from '@material-ui/core';
import { Edit as EditIcon, RotateLeftTwoTone } from '@material-ui/icons';
import threeApp from '../../common/three/app';
import {
  initThreeContainer as initThreeContainerAction,
  renderThreeContainer as renderThreeContainerAction,
  updateThreeContainer as updateThreeContainerAction
} from '../../actions/threejs';
import { initPlayground as initPlaygroundAction } from '../../actions/playground';
import { hasDeletableModels as hasDeletableModelsHandler } from '../../reducers/playground';
import { startDelete as startDeleteAction } from '../../actions/attachedModel';
import styles from './Playground.module.scss';
import { FETCHING } from '../../constants/fetchingStates';

const containerId = 'three-container';

const getDimensionsByElementId = elementId => {
  const container = document.getElementById(elementId);
  const { clientWidth, clientHeight } = container;

  return {
    width: clientWidth,
    height: clientHeight
  };
};

class Playground extends Component {
  componentDidMount() {
    const {
      initPlayground,
      initThreeContainer,
      updateThreeContainer
    } = this.props;
    const { width, height } = getDimensionsByElementId(containerId);

    const threejsContainer = document.getElementById('threejs-container');

    initThreeContainer(width, height, threejsContainer);
    initPlayground();

    this.mount.appendChild(threeApp.getRendererDomElement());
    window.addEventListener('resize', () => {
      const {
        width: resizedWidth,
        height: resizedHeight
      } = getDimensionsByElementId(containerId);
      updateThreeContainer(resizedWidth, resizedHeight);
    });

    this.start();
  }

  // TODO: refactor ??? - move animation to three js singleton

  start = () => {
    if (!this.frameId) {
      this.frameId = window.requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    window.cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    const { renderThreeContainer } = this.props;
    renderThreeContainer();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  renderSpinner = () => {
    return (
      <div className={styles.SpinnerWrapper}>
        <LinearProgress />
      </div>
    );
  };

  render() {
    const {
      fetchingState,
      editType,
      hasDeletableModels,
      startDelete
    } = this.props;
    return (
      <React.Fragment>
        <div id="threejs-container" className={styles.Playground}>
          {fetchingState === FETCHING && this.renderSpinner()}
          <div
            id={containerId}
            className={styles.ThreeContainer}
            ref={mount => {
              this.mount = mount;
            }}
          />
        </div>
        <Fab
          size="small"
          onClick={threeApp.rotateCamera}
          className={styles.FloatingButton}
          style={{ right: '12px' }}
        >
          <RotateLeftTwoTone />
        </Fab>
        <Zoom in={!editType && hasDeletableModels}>
          <Fab
            size="small"
            onClick={startDelete}
            className={styles.FloatingButton}
            style={{ right: '64px' }}
          >
            <EditIcon />
          </Fab>
        </Zoom>
      </React.Fragment>
    );
  }
}

Playground.propTypes = {
  fetchingState: PropTypes.string.isRequired,
  initPlayground: PropTypes.func.isRequired,
  initThreeContainer: PropTypes.func.isRequired,
  renderThreeContainer: PropTypes.func.isRequired,
  updateThreeContainer: PropTypes.func.isRequired,
  editType: PropTypes.string
};

const mapStateToProps = state => ({
  models: state.playground.models,
  fetchingState: state.playground.fetchingState,
  editType: state.attachedModel.editType,
  hasDeletableModels: hasDeletableModelsHandler(state.playground)
});

const mapDispatchToProps = dispatch => ({
  initPlayground: bindActionCreators(initPlaygroundAction, dispatch),
  initThreeContainer: bindActionCreators(initThreeContainerAction, dispatch),
  renderThreeContainer: bindActionCreators(
    renderThreeContainerAction,
    dispatch
  ),
  updateThreeContainer: bindActionCreators(
    updateThreeContainerAction,
    dispatch
  ),
  startDelete: bindActionCreators(startDeleteAction, dispatch)
});

const connector = R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(Playground);
