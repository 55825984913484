import {
  INIT_THREE_CONTAINER,
  UPDATE_THREE_CONTAINER,
  RENDER_THREE_CONTAINER,
  ZOOM_CAMERA,
  UNZOOM_CAMERA,
  ROTATE_CAMERA,
  ROTATE_CAMERA_REVERSE
} from '../constants/ActionTypes';

export const initThreeContainer = (width, height, threejsContainer) => ({
  type: INIT_THREE_CONTAINER,
  payload: {
    width,
    height,
    threejsContainer
  }
});

export const updateThreeContainer = (resizedWidth, resizedHeight) => ({
  type: UPDATE_THREE_CONTAINER,
  payload: {
    resizedWidth,
    resizedHeight
  }
});

export const renderThreeContainer = () => ({
  type: RENDER_THREE_CONTAINER
});

export const zoomCamera = () => ({
  type: ZOOM_CAMERA
});

export const unzoomCamera = () => ({
  type: UNZOOM_CAMERA
});

export const rotateCamera = () => ({
  type: ROTATE_CAMERA
});

export const rotateCameraReverse = () => ({
  type: ROTATE_CAMERA_REVERSE
});
