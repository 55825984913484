import * as R from 'ramda';

// const s1Top = ['s1-t2r', 's1-t1r', 's1-t1', 's1-t2'];
// const s1Bottom = ['s1-b2r', 's1-b1r', 's1-b1', 's1-b2'];

const front = ['f'];
// const front = ['f', 'f4', 'f4r', 'f4s', 'f4sr'];
// const frontDisable = [...front, 't1', 't2', 't3', 'b1', 'b2', 'b3'];
const back = ['k'];
// const back = ['k', 'k4', 'k4r', 'k4s', 'k4sr'];
// const backDisable = [...back, 't5', 't6', 't7', 'b5', 'b6', 'b7'];

export default (
  disablePositionsArray = [],
  disableSides = [],
  positions = []
) => {
  const allDisabledPositions = [...disablePositionsArray, ...disableSides];
  if (R.any(position => R.includes(position, disableSides), front)) {
    R.forEach(position => allDisabledPositions.push(position), front);
  }
  if (R.any(position => R.includes(position, disableSides), back)) {
    R.forEach(position => allDisabledPositions.push(position), back);
  }
  return {
    disabledPositions: allDisabledPositions,
    newPositions: R.compose(
      R.filter(position => !R.includes(position, allDisabledPositions))
    )(positions)
  };
};
