import playgroundReducer from './playground';
import commonReducer from './common';
import attachedModelReducer from './attachedModel';

export default function mainReducer(state = {}, action) {
  return {
    playground: playgroundReducer(state.playground, action),
    common: commonReducer(state.common, action),
    attachedModel: attachedModelReducer(state.attachedModel, action)
  };
}
