import * as R from 'ramda';

import {
  SELECT_ATTACHED_MODEL,
  START_DELETE_MODEL,
  ADD_ATTACHED_MODEL,
  SAVE_ATTACHED_MODEL,
  DELETE_MODEL,
  CANCEL_ATTACHED_MODEL,
  MOVE_ATTACHED_MODEL,
  SET_DELETE_MODEL,
  SET_ROOT_MODEL,
  SET_POSITION,
  PREVIEW_CLUTCH,
  PREVIEW_SCREW,
  FETCH_MODEL,
  FETCHED_MODEL
} from '../constants/ActionTypes';

import {
  selectModel,
  unselectModel,
  deleteModel as deleteModelAction,
  deleteClutch,
  deleteScrew
} from './playground';

import * as fetchingStates from '../constants/fetchingStates';
import * as editTypes from '../constants/EditTypes';
import getDeleteableModelsFromModels from '../common/utils/getDeleteableModelsFromModels';
import getReversePosition from '../common/utils/getReversePosition';
import getConnectionsObject from '../common/utils/getConnectionsObject';
import getDefaultPositions from '../common/utils/getDefaultPositionsFromModelConfig';
import getModelConfigFromCommonState from '../common/utils/getModelConfigFromCommonState';
import getRelativePositions from '../common/utils/getRelativePositions';
import getRootModelAttachablePositions from '../common/utils/getRootModelAttachablePositions';
import disablePositions from '../common/utils/disablePositions';
import getPositionConfigFromModelConfig from '../common/utils/getPositionConfigFromModelConfig';
import getAttachableRootModels from '../common/utils/getAttachableRootModels';

/*
  HELPER ACTIONS
*/

const previewClutch = (clutchName, position, rootModel) => (
  dispatch,
  getState
) => {
  const { attachedModel: attachedModelState, common: commonState } = getState();
  const { clutches } = attachedModelState;

  const clutchConfig = getModelConfigFromCommonState(clutchName)(commonState);
  const clutchPositionConfig = getPositionConfigFromModelConfig(
    position,
    clutchConfig
  );

  R.forEach(clutch => dispatch(deleteClutch(clutch.uuid)), clutches);

  if (!clutchPositionConfig) {
    console.warn(`no config for clutch '${clutchName}'`);
    return;
  }

  getRelativePositions(
    rootModel.position,
    rootModel.rotation,
    clutchPositionConfig
  ).forEach((clutchPosition, key, arr) => {
    dispatch({ type: FETCH_MODEL });
    dispatch({
      type: PREVIEW_CLUTCH,
      payload: {
        modelName: clutchName,
        options: {
          relativePositions: [clutchPosition],
          displayName: clutchConfig.display_name,
          disablePositions: clutchConfig.disablePositions,
          disableSides: clutchConfig.disableSides,
          // when a clutch is fully loaded
          onComplete: attachedModel => dispatch({ type: FETCHED_MODEL })
        }
      }
    });
  });
};

const previewScrews = (
  position,
  rootModel,
  modelType,
  screwName = 'sroub_maly'
) => (dispatch, getState) => {
  const { attachedModel: attachedModelState, common: commonState } = getState();
  const { attachedScrews, rootScrews } = attachedModelState;

  const screwConfig = getModelConfigFromCommonState(screwName)(commonState);
  const screwPositionConfig = getPositionConfigFromModelConfig(
    position,
    screwConfig
  );

  R.forEach(screw => dispatch(deleteScrew(screw.uuid)), attachedScrews);
  R.forEach(screw => dispatch(deleteScrew(screw.uuid)), rootScrews);

  if (!screwPositionConfig) {
    console.warn(`no config for screw '${screwName}' on position ${position}`);
    return;
  }

  getRelativePositions(
    rootModel.position,
    rootModel.rotation,
    screwPositionConfig
  ).forEach((screwPosition, key, arr) => {
    dispatch({ type: FETCH_MODEL });
    dispatch({
      type: PREVIEW_SCREW,
      payload: {
        modelName: screwName,
        options: {
          relativePositions: [screwPosition],
          displayName: screwConfig.display_name,
          // after screw is fully loaded
          onComplete: attachedModel => dispatch({ type: FETCHED_MODEL })
        },
        modelType
      }
    });
  });
};

const _removeStuckedScrews = () => (dispatch, getState) => {
  const { attachedModel: attachedModelState } = getState();
  const { attachedScrews, rootScrews } = attachedModelState;

  R.forEach(screw => dispatch(deleteScrew(screw.uuid)), attachedScrews);
  R.forEach(screw => dispatch(deleteScrew(screw.uuid)), rootScrews);
  dispatch({ type: FETCHED_MODEL });
};

const addAttachedModel = (modelName, options) => (dispatch, getState) => {
  const { common: commonState } = getState();
  const modelConfig = getModelConfigFromCommonState(modelName)(commonState);
  dispatch({
    type: ADD_ATTACHED_MODEL,
    payload: {
      modelName,
      options: {
        ...options,
        availablePositions: getDefaultPositions(modelConfig)
      }
    }
  });
};

const moveAttachedModel = (uuid, options) => {
  return {
    type: MOVE_ATTACHED_MODEL,
    payload: {
      uuid,
      options
    }
  };
};

const setScrews = (rootModel, attachedModel, position, dispatch) => {
  const positions = {
    l3: 'p3l',
    l5: 'p5l',
    r3: 'p3r',
    r5: 'p5r',
    o0: 'p5l',
    o1: 'p5l',
    o2: 'p5l',
    o3: 'p5l',
    o4: 'p5l',
    o5: 'p5l',
    o6: 'p5l',
    o7: 'p5l',
    o8: 'p3l',
    o9: 'p3l',
    o10: 'p3l',
    o11: 'p3l',
    o12: 'p3l',
    o13: 'p3l',
    o14: 'p3l',
    o15: 'p3l'
  };
  if (attachedModel.modelName === 'panel' && rootModel.modelName === 'panel') {
    if (position === 'l' || position === 'r') {
      dispatch(previewScrews(position, rootModel, 'root', 'sroub_velky'));
      dispatch(previewScrews(position, rootModel, 'root', 'matice'));
      return;
    }
    dispatch(previewScrews(position, rootModel, 'root'));
    const previewPosition = position[0] === 'b' ? 't0' : 'b1';
    dispatch(previewScrews(previewPosition, attachedModel, 'attached'));
  } else if (attachedModel.modelName === 'C1' || rootModel.modelName === 'C1') {
    if (attachedModel.modelName === 'NEREZ_TYC') {
      dispatch(previewScrews(position, rootModel, 'root', 'matice'));
      if (positions[position])
        dispatch(
          previewScrews(
            positions[position],
            attachedModel,
            'attached',
            'matice'
          )
        );
    } else if (rootModel.modelName === 'NEREZ_TYC') {
      dispatch(_removeStuckedScrews(position, rootModel, 'root'));
    } else {
      dispatch(previewScrews(position, rootModel, 'root', 'sroub_maly'));
      dispatch(previewScrews(position, rootModel, 'root', 'matice'));
    }
  } else if (attachedModel.modelName === 'SKLUZAVKA_DOUBLE') {
    dispatch(previewScrews(position, rootModel, 'root'));
  } else if (attachedModel.modelName === 's1') {
    dispatch(previewScrews(position, rootModel, 'root', 'sroub_maly'));
    dispatch(previewScrews(position, rootModel, 'root', 'sroub_velky'));
    dispatch(previewScrews(position, rootModel, 'root', 'matice'));
  } else if (attachedModel.modelName === 'NEREZ_TYC') {
    dispatch(previewScrews(position, rootModel, 'root', 'matice'));
    if (positions[position])
      dispatch(
        previewScrews(positions[position], attachedModel, 'attached', 'matice')
      );
  } else if (rootModel.modelName === 'NEREZ_TYC') {
    dispatch(_removeStuckedScrews(position, rootModel, 'root'));
  }
};

const setScrewsOnComplete = (
  rootModel,
  defaultPosition,
  dispatch
) => attachedModel => {
  dispatch({ type: FETCHED_MODEL });
  setScrews(rootModel, attachedModel, defaultPosition, dispatch);
};

/*
  ATTACHED MODEL ACTIONS
*/

export const save = () => (dispatch, getState) => {
  const {
    attachedModel: attachedModelState,
    playground: playgroundState
  } = getState();
  const {
    rootModel,
    attachedModel,
    clutches: newClutches,
    rootScrews,
    attachedScrews,
    position,
    deleteModel,
    disablePositions: disablePositionsArray = [],
    disableSides: disableSidesArray
  } = attachedModelState;
  const { selectedModels, clutches, screws } = playgroundState;

  if (!attachedModel || !rootModel) {
    console.warn('nothing to save');
    return;
  }
  const {
    newPositions: newAttachedPositions,
    disabledPositions: attachedDisabledPositions
  } = disablePositions(
    R.map(getReversePosition, disablePositionsArray),
    disableSidesArray,
    attachedModel.positions
  );
  const {
    newPositions: newRootPositions,
    disabledPositions: rootDisabledPositions
  } = disablePositions(
    disablePositionsArray,
    disableSidesArray,
    rootModel.positions
  );

  const newAttachedModel = {
    ...attachedModel,
    connections: [
      getConnectionsObject(
        rootModel,
        position,
        !R.isEmpty(newClutches) ? R.last(newClutches).modelName : null,
        rootScrews,
        attachedScrews,
        newClutches,
        attachedDisabledPositions,
        disableSidesArray
      )
    ],
    positions: newAttachedPositions
  };

  const newRootModel = {
    ...rootModel,
    connections: [
      ...(rootModel.connections || []),
      getConnectionsObject(
        attachedModel,
        getReversePosition(position),
        !R.isEmpty(newClutches) ? R.last(newClutches).modelName : null,
        attachedScrews,
        rootScrews,
        newClutches,
        rootDisabledPositions,
        disableSidesArray
      )
    ],
    positions: newRootPositions
  };

  R.forEach(model => dispatch(unselectModel(model.uuid)), selectedModels);
  R.forEach(clutch => dispatch(unselectModel(clutch.uuid)), clutches);
  R.forEach(screw => dispatch(unselectModel(screw.uuid)), screws);

  const clutchesWithConnections = R.map(
    clutch => ({
      ...clutch,
      connections: [
        getConnectionsObject(newAttachedModel),
        getConnectionsObject(newRootModel)
      ]
    }),
    newClutches
  );
  const attachedScrewsWithConnections = R.map(
    screw => ({
      ...screw,
      connectedModelsUuids: [getConnectionsObject(newAttachedModel)]
    }),
    attachedScrews
  );
  const rootScrewsWithConnections = R.map(
    screw => ({
      ...screw,
      connectedModelsUuids: [getConnectionsObject(newRootModel)]
    }),
    rootScrews
  );

  dispatch({
    type: SAVE_ATTACHED_MODEL,
    payload: {
      newAttachedModel,
      newRootModel,
      clutches: clutchesWithConnections,
      screws: [...attachedScrewsWithConnections, ...rootScrewsWithConnections],
      deleteModel
    }
  });
};

export const cancel = () => (dispatch, getState) => {
  const {
    attachedModel: attachedModelState,
    playground: playgroundState
  } = getState();
  const {
    editType,
    attachedModel,
    clutches,
    attachedScrews,
    rootScrews
  } = attachedModelState;
  const { selectedModels } = playgroundState;

  R.forEach(model => dispatch(unselectModel(model.uuid)), selectedModels);

  if (editType === editTypes.ATTACH) {
    R.forEach(clutch => dispatch(deleteClutch(clutch.uuid)), clutches);
    R.forEach(screw => dispatch(deleteScrew(screw.uuid)), [
      ...attachedScrews,
      ...rootScrews
    ]);
    if (attachedModel) dispatch(deleteModelAction(attachedModel.uuid));
  }

  dispatch({
    type: CANCEL_ATTACHED_MODEL,
    payload: {}
  });
};

export const handleDelete = () => (dispatch, getState) => {
  const { attachedModel: attachedModelState } = getState();
  const {
    deleteModel,
    clutches,
    rootScrews,
    attachedScrews
  } = attachedModelState;

  dispatch(deleteModelAction(deleteModel.uuid));
  R.forEach(clutch => dispatch(deleteClutch(clutch.uuid)), clutches);
  R.forEach(screw => dispatch(deleteScrew(screw.uuid)), [
    ...rootScrews,
    ...attachedScrews
  ]);

  dispatch({
    type: DELETE_MODEL,
    payload: {
      deleteModelUuid: deleteModel.uuid
    }
  });
};

export const select = modelName => (dispatch, getState) => {
  const {
    attachedModel: attachedModelState,
    common: commonState,
    playground: playgroundState
  } = getState();
  const { models, clutches, screws } = playgroundState;

  if (commonState.configFetchingState !== fetchingStates.FETCHED) {
    console.warn('config should be loaded before selecting model');
    return;
  }

  if (attachedModelState.attachedModel) {
    dispatch(save());
    /*
    rootModel = {
      ...attachedModelState.attachedModel,
      connections: [
        ...(attachedModelState.attachedModel.connections || []),
        getConnectionsObject(
          attachedModelState.attachedModel,
          getReversePosition(attachedModelState.position),
          !R.isEmpty(attachedModelState.clutches)
            ? R.last(attachedModelState.clutches).modelName
            : null,
          attachedModelState.attachedScrews,
          attachedModelState.rootScrews,
          attachedModelState.Clutches
        )
      ]
    };
    */
    setTimeout(() => dispatch(select(modelName)), 100);
    return;
  }

  if (attachedModelState.deleteModel) {
    dispatch(cancel());
    setTimeout(() => dispatch(select(modelName)), 100);
    return;
  }

  const modelConfig = getModelConfigFromCommonState(modelName)(commonState);

  // filter models (rootModels have at least one available position)
  const rootModels = getAttachableRootModels(modelConfig, models);
  if (R.isEmpty(rootModels)) {
    console.warn(`no model to attach '${modelName}'`);
    return;
  }

  const rootModel = R.last(rootModels);

  const rootModelAttachablePositions = getRootModelAttachablePositions(
    modelConfig,
    rootModel.positions
  );

  if (R.isEmpty(rootModelAttachablePositions)) {
    console.warn(`there is no model to attach '${modelName}' model`);
    return;
  }

  const defaultPosition = R.path([0], rootModelAttachablePositions);

  const positionConfig = getPositionConfigFromModelConfig(
    defaultPosition,
    modelConfig
  );
  // console.log('positionConfig', positionConfig);
  dispatch({ type: FETCH_MODEL });
  dispatch(
    addAttachedModel(modelName, {
      relativePositions: getRelativePositions(
        rootModel.position,
        rootModel.rotation,
        positionConfig
      ),
      displayName: modelConfig.display_name,
      onComplete: setScrewsOnComplete(rootModel, defaultPosition, dispatch)
    })
  );

  const clutchName = R.path(['clutch_name'], positionConfig);
  dispatch(previewClutch(clutchName, defaultPosition, rootModel));

  R.compose(
    R.forEach(model => dispatch(selectModel(model.uuid))),
    R.filter(model => model.uuid !== rootModel.uuid)
  )(models);
  R.forEach(clutch => dispatch(selectModel(clutch.uuid)))(clutches);
  R.forEach(screw => dispatch(selectModel(screw.uuid)))(screws);

  dispatch({
    type: SELECT_ATTACHED_MODEL,
    payload: {
      editType: editTypes.ATTACH,
      rootModel,
      rootModels,
      defaultPosition,
      rootModelAttachablePositions,
      disablePositions: positionConfig.disablePositions,
      disableSides: positionConfig.disableSides
    }
  });
};

export const startDelete = () => (dispatch, getState) => {
  const { playground: playgroundState } = getState();
  const { models, clutches: originClutches, screws } = playgroundState;

  const editType = editTypes.DELETE;
  const deleteableModels = getDeleteableModelsFromModels(models);
  const deleteModel = R.last(deleteableModels);

  const connection = deleteModel.connections[0];

  const clutches = R.filter(
    clutch => R.includes(clutch.uuid, connection.clutchesUuids),
    originClutches
  );
  const rootScrews = R.filter(
    screw => R.includes(screw.uuid, connection.connectedModelScrewsUuids),
    screws
  );
  const attachedScrews = R.filter(
    screw => R.includes(screw.uuid, connection.ownScrewsUuids),
    screws
  );

  dispatch(selectModel(deleteModel.uuid));
  R.forEach(clutch => dispatch(selectModel(clutch.uuid)))(clutches);
  R.forEach(screw => dispatch(selectModel(screw.uuid)))(attachedScrews);
  R.forEach(screw => dispatch(selectModel(screw.uuid)))(rootScrews);

  dispatch({
    type: START_DELETE_MODEL,
    payload: {
      editType,
      deleteModel,
      clutches,
      rootScrews,
      attachedScrews,
      deleteableModels
    }
  });
};

const setDeleteModel = indexDiff => (dispatch, getState) => {
  const {
    playground: playgroundState,
    attachedModel: attachedModelState
  } = getState();

  const { screws: originScrews, clutches: originClutches } = playgroundState;
  const {
    deleteModel,
    deleteableModels,
    clutches: currentClutches,
    rootScrews: currentRootScrews,
    attachedScrews: currentAttachedScrews
  } = attachedModelState;

  // TODO: loop indexing (now it is work only for indexDiff +1/-1)
  const currentDeleteModelUuid = deleteModel.uuid;
  let newDeleteableIndex =
    R.findIndex(
      model => model.uuid === currentDeleteModelUuid,
      deleteableModels
    ) + indexDiff;
  if (newDeleteableIndex >= R.length(deleteableModels)) {
    newDeleteableIndex = 0;
  }
  if (newDeleteableIndex < 0) {
    newDeleteableIndex = R.length(deleteableModels) - 1;
  }
  const newDeleteModel = R.path([newDeleteableIndex], deleteableModels);
  const { uuid: newDeleteModelUuid } = newDeleteModel;

  const connection = newDeleteModel.connections[0];

  const newClutches = R.filter(
    clutch => R.includes(clutch.uuid, connection.clutchesUuids),
    originClutches
  );
  const newRootScrews = R.filter(
    screw => R.includes(screw.uuid, connection.connectedModelScrewsUuids),
    originScrews
  );
  const newAttachedScrews = R.filter(
    screw => R.includes(screw.uuid, connection.ownScrewsUuids),
    originScrews
  );

  if (currentDeleteModelUuid !== newDeleteModelUuid) {
    dispatch(selectModel(newDeleteModelUuid));
    dispatch(unselectModel(currentDeleteModelUuid));
    R.forEach(model => dispatch(unselectModel(model.uuid)), [
      ...currentClutches,
      ...currentAttachedScrews,
      ...currentRootScrews
    ]);
    R.forEach(model => dispatch(selectModel(model.uuid)), [
      ...newClutches,
      ...newAttachedScrews,
      ...newRootScrews
    ]);
  }

  dispatch({
    type: SET_DELETE_MODEL,
    payload: {
      deleteModel: newDeleteModel,
      clutches: newClutches,
      rootScrews: newRootScrews,
      attachedScrews: newAttachedScrews
    }
  });
};

export const nextDeleteModel = () => dispatch => {
  dispatch(setDeleteModel(+1));
};

export const previousDeleteModel = () => dispatch => {
  dispatch(setDeleteModel(-1));
};

const setRootModel = indexDiff => (dispatch, getState) => {
  const { attachedModel: attachedModelState, common: commonState } = getState();
  const { rootModel, rootModels, attachedModel } = attachedModelState;

  // get new root uuid
  // TODO: loop indexing (now it is work only for indexDiff +1/-1)
  const currentRootModelUuid = rootModel.uuid;
  let newRootIndex =
    R.findIndex(model => model.uuid === currentRootModelUuid, rootModels) +
    indexDiff;
  if (newRootIndex >= R.length(rootModels)) {
    newRootIndex = 0;
  }
  if (newRootIndex < 0) {
    newRootIndex = R.length(rootModels) - 1;
  }
  const newRootModel = R.path([newRootIndex], rootModels);
  const { uuid: newRootModelUuid } = newRootModel;

  // select correct root model
  if (currentRootModelUuid !== newRootModelUuid) {
    dispatch(selectModel(currentRootModelUuid));
    dispatch(unselectModel(newRootModelUuid));
  }

  const modelConfig = getModelConfigFromCommonState(attachedModel.modelName)(
    commonState
  );

  const newRootModelAttachablePositions = getRootModelAttachablePositions(
    modelConfig,
    newRootModel.positions
  );

  if (R.isEmpty(newRootModelAttachablePositions)) {
    console.warn('rootModel has to have attachable positions');
    return;
  }

  const defaultPosition = R.path([0], newRootModelAttachablePositions);

  const positionConfig = getPositionConfigFromModelConfig(
    defaultPosition,
    modelConfig
  );

  setScrews(newRootModel, attachedModel, defaultPosition, dispatch);

  dispatch(
    moveAttachedModel(attachedModel.uuid, {
      relativePosition: R.last(
        getRelativePositions(
          newRootModel.position,
          newRootModel.rotation,
          positionConfig
        )
      )
    })
  );

  const clutchName = R.path(['clutch_name'], positionConfig);
  dispatch(previewClutch(clutchName, defaultPosition, newRootModel));

  dispatch({
    type: SET_ROOT_MODEL,
    payload: {
      defaultPosition,
      rootModelAttachablePositions: newRootModelAttachablePositions,
      rootModel: newRootModel,
      disablePositions: positionConfig.disablePositions,
      disableSides: positionConfig.disableSides
    }
  });
};

export const nextRootModel = () => dispatch => {
  dispatch(setRootModel(+1));
};

export const previousRootModel = () => dispatch => {
  dispatch(setRootModel(-1));
};

const setPosition = indexDiff => (dispatch, getState) => {
  const { attachedModel: attachedModelState, common: commonState } = getState();
  const {
    position: currentPosition,
    attachedModel,
    rootModel,
    rootModelAttachablePositions
  } = attachedModelState;

  let newPositionIndex =
    R.findIndex(
      position => position === currentPosition,
      rootModelAttachablePositions
    ) + indexDiff;
  if (newPositionIndex >= R.length(rootModelAttachablePositions)) {
    newPositionIndex = 0;
  }
  if (newPositionIndex < 0) {
    newPositionIndex = R.length(rootModelAttachablePositions) - 1;
  }

  const { modelName } = attachedModel;
  const modelConfig = getModelConfigFromCommonState(modelName)(commonState);
  const newPosition = rootModelAttachablePositions[newPositionIndex];

  const positionConfig = getPositionConfigFromModelConfig(
    newPosition,
    modelConfig
  );

  dispatch(
    moveAttachedModel(attachedModel.uuid, {
      relativePosition: R.last(
        getRelativePositions(
          rootModel.position,
          rootModel.rotation,
          positionConfig
        )
      )
    })
  );

  setScrews(rootModel, attachedModel, newPosition, dispatch);

  const clutchName = R.path(['clutch_name'], positionConfig);
  dispatch(previewClutch(clutchName, newPosition, rootModel));

  console.log(newPosition);

  dispatch({
    type: SET_POSITION,
    payload: {
      position: newPosition,
      disablePositions: positionConfig.disablePositions,
      disableSides: positionConfig.disableSides
    }
  });
};

export const nextPosition = () => dispatch => {
  dispatch(setPosition(+1));
};

export const previousPosition = () => dispatch => {
  dispatch(setPosition(-1));
};
