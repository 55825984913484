import * as R from 'ramda';
import {
  SELECT_ATTACHED_MODEL,
  START_DELETE_MODEL,
  ADD_ATTACHED_MODEL,
  SAVE_ATTACHED_MODEL,
  DELETE_MODEL,
  CANCEL_ATTACHED_MODEL,
  SET_DELETE_MODEL,
  SET_ROOT_MODEL,
  SET_POSITION,
  MOVE_ATTACHED_MODEL,
  PREVIEW_CLUTCH,
  DELETE_CLUTCH,
  PREVIEW_SCREW,
  DELETE_SCREW
} from '../constants/ActionTypes';

const defaultState = {
  editType: null,
  attachedModel: null,
  position: null,
  rootModel: null,
  rootModels: [],
  rootModelAttachablePositions: [],
  clutches: [],
  rootScrews: [],
  attachedScrews: [],
  deleteableModels: [],
  disablePositions: [],
  disableSides: [],
  originAttachedModel: null, // origin model (origin position, rotation, attached modele/screws/clutches)
  originRootModel: null // origin model (origin position, rotation, attached modele/screws/clutches)
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_ATTACHED_MODEL: {
      return {
        ...state,
        editType: action.payload.editType,
        position: action.payload.defaultPosition,
        rootModel: action.payload.rootModel,
        rootModels: action.payload.rootModels,
        rootModelAttachablePositions:
          action.payload.rootModelAttachablePositions,
        disablePositions: action.payload.disablePositions,
        disableSides: action.payload.disableSides
      };
    }
    case START_DELETE_MODEL: {
      return { ...action.payload };
    }
    case ADD_ATTACHED_MODEL: {
      return {
        ...state,
        attachedModel: action.payload.newAttachedModel
      };
    }
    case DELETE_MODEL:
    case SAVE_ATTACHED_MODEL:
    case CANCEL_ATTACHED_MODEL: {
      return defaultState;
    }
    case SET_DELETE_MODEL: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_ROOT_MODEL: {
      return {
        ...state,
        rootModel: action.payload.rootModel,
        position: action.payload.defaultPosition,
        rootModelAttachablePositions:
          action.payload.rootModelAttachablePositions,
        disablePositions: action.payload.disablePositions,
        disableSides: action.payload.disableSides
      };
    }
    case SET_POSITION: {
      return {
        ...state,
        position: action.payload.position,
        disablePositions: action.payload.disablePositions,
        disableSides: action.payload.disableSides
      };
    }
    case MOVE_ATTACHED_MODEL: {
      return {
        ...state,
        attachedModel: { ...state.attachedModel, ...action.payload.model }
      };
    }
    case PREVIEW_CLUTCH: {
      return {
        ...state,
        clutches: [...state.clutches, action.payload.newAttachedModel]
      };
    }
    case DELETE_CLUTCH: {
      return {
        ...state,
        clutches: R.filter(clutch => clutch.uuid !== action.payload.uuid)(
          state.clutches
        )
      };
    }
    case PREVIEW_SCREW: {
      return {
        ...state,
        [`${action.payload.modelType}Screws`]: [
          ...state[`${action.payload.modelType}Screws`],
          action.payload.newAttachedModel
        ]
      };
    }
    case DELETE_SCREW: {
      return {
        ...state,
        rootScrews: R.filter(screw => screw.uuid !== action.payload.uuid)(
          state.rootScrews
        ),
        attachedScrews: R.filter(screw => screw.uuid !== action.payload.uuid)(
          state.attachedScrews
        )
      };
    }
    default:
      return state;
  }
};
