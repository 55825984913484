import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { select as selectModelAction } from '../../actions/attachedModel';
import ModelItem from '../../components/ModelItem';
import getAttachableModels from '../../common/utils/getAttachableModels';

import styles from './Models.module.scss';
import { FETCHED } from '../../constants/fetchingStates';

const Models = ({ config, models, selectModel, fetchingState }) => {
  const handleSelectModel = modelName => {
    return () => selectModel(modelName);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>
        {R.map(model => (
          <ModelItem
            key={model.modelName}
            modelName={model.modelName}
            displayName={model.displayName}
            selectModel={handleSelectModel(model.modelName)}
            disabled={fetchingState !== FETCHED}
          />
        ))(getAttachableModels(config, models) || [])}
      </div>
    </div>
  );
};

Models.propTypes = {
  config: PropTypes.arrayOf(PropTypes.object),
  models: PropTypes.arrayOf(PropTypes.object),
  selectModel: PropTypes.func.isRequired,
  fetchingState: PropTypes.string.isRequired
};

Models.defaultProps = {
  config: [],
  models: []
};

const mapStateToProps = state => ({
  config: state.common.config,
  models: state.playground.models,
  fetchingState: state.playground.fetchingState
});

const mapDispatchToProps = dispatch => ({
  selectModel: bindActionCreators(selectModelAction, dispatch)
});

const connector = R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default connector(Models);
