import * as R from 'ramda';
import {
  INIT_THREE_CONTAINER,
  UPDATE_THREE_CONTAINER,
  RENDER_THREE_CONTAINER,
  ZOOM_CAMERA,
  UNZOOM_CAMERA,
  ROTATE_CAMERA,
  ROTATE_CAMERA_REVERSE,
  ADD_MODEL,
  DELETE_MODEL,
  SELECT_MODEL,
  UNSELECT_MODEL,
  ADD_ATTACHED_MODEL,
  MOVE_ATTACHED_MODEL,
  ADD_CLUTCH,
  PREVIEW_CLUTCH,
  DELETE_CLUTCH,
  ADD_SCREW,
  PREVIEW_SCREW,
  DELETE_SCREW,
  FETCH_MODEL,
  FETCHED_MODEL
} from '../constants/ActionTypes';
import threeApp from '../common/three/app';

export default () => next => action => {
  switch (action.type) {
    // try catching error (todo: is it good idea???)
    case INIT_THREE_CONTAINER: {
      const newAction = { ...action };
      try {
        const { width, height, threejsContainer } = action.payload;
        threeApp.init(width, height, threejsContainer);
        newAction.payload.succesfull = true;
      } catch (err) {
        newAction.payload.succesfull = false;
      }
      next(newAction);
      return;
    }
    case UPDATE_THREE_CONTAINER: {
      const { resizedWidth, resizedHeight } = action.payload;
      threeApp.onResize(resizedWidth, resizedHeight);

      // catched action - devtools usage
      // next(action);
      return;
    }
    case RENDER_THREE_CONTAINER: {
      threeApp.render();
      // catched action - devtools usage
      // next(action);
      return;
    }
    case ZOOM_CAMERA: {
      threeApp.zoom();
      next(action);
      return;
    }
    case UNZOOM_CAMERA: {
      threeApp.unzoom();
      next(action);
      return;
    }
    case ROTATE_CAMERA: {
      threeApp.rotate();
      next(action);
      return;
    }
    case ROTATE_CAMERA_REVERSE: {
      threeApp.rotateReverse();
      next(action);
      return;
    }
    case ADD_CLUTCH:
    case PREVIEW_CLUTCH:
    case ADD_SCREW:
    case PREVIEW_SCREW:
    case ADD_ATTACHED_MODEL:
    case ADD_MODEL: {
      next({ type: FETCH_MODEL });
      const {
        modelName,
        options,
        connections,
        connectedModelsUuids
      } = action.payload;
      const { displayName = modelName } = options;
      const relativePosition = R.pathOr(
        null,
        ['relativePositions', 0, 'relativePosition'],
        options
      );
      threeApp.addModel(modelName, options, modelDataToStore => {
        const newAttachedModel = {
          ...modelDataToStore,
          connections,
          connectedModelsUuids,
          displayName,
          relativePosition
        };
        if (options.onComplete) options.onComplete(newAttachedModel);
        next({
          type: action.type,
          payload: {
            ...action.payload,
            newAttachedModel
          }
        });
      });

      // TODO: back to callback but with promises (wait until all relative positions are loaded)
      next({ type: FETCHED_MODEL });
      return;
    }
    case DELETE_CLUTCH:
    case DELETE_SCREW:
    case DELETE_MODEL: {
      threeApp.deleteModel(action.payload.uuid);
      next(action);
      return;
    }
    case SELECT_MODEL: {
      const selectedModel = threeApp.highlightModel(action.payload.uuid);
      next({
        type: action.type,
        payload: {
          ...action.payload,
          model: selectedModel
        }
      });
      return;
    }
    case UNSELECT_MODEL: {
      threeApp.defaultModelColor(action.payload.uuid);
      next(action);
      return;
    }
    case MOVE_ATTACHED_MODEL: {
      const { uuid, options } = action.payload;
      const movedModel = threeApp.moveModel(uuid, options);
      next({
        ...action,
        payload: {
          ...action.payload,
          model: movedModel
        }
      });
      return;
    }
    case FETCH_MODEL: {
      next(action);
      break;
    }
    case FETCHED_MODEL: {
      next(action);
      break;
    }
    default: {
      next(action);
    }
  }
};
