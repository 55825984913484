import * as R from 'ramda';

export default models => {
  const onlyOnePanel =
    R.length(R.filter(model => model.modelName === 'panel', models)) === 1;
  return R.filter(
    model =>
      R.length(model.connections) === 1 &&
      (model.modelName !== 'panel' || !onlyOnePanel),
    models
  );
};
