export const cameraDefaulPosition = {
  x: 0,
  y: 10,
  z: 50
};
export const cameraGraduation = (45 / 180) * Math.PI; // 45° in radians

export const initialZoom = 20;
export const zoomGraduation = 0.1;
export const LEFT = -1;
export const RIGHT = 1;
export const zPositions = [0, 1.15, 2];
