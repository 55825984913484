const positionMap = {
  t0: 'b4',
  t1: 'b3',
  t2: 'b2',
  t3: 'b1',
  t5: 'b7',
  t6: 'b6',
  t7: 'b5',
  b1: 't3',
  b2: 't2',
  b3: 't1',
  b4: 't0',
  b5: 't7',
  b6: 't6',
  b7: 't5',
  l: 'r',
  r: 'l',
  l5: 'r5',
  l3: 'r3',
  r5: 'l5',
  r3: 'l3',
  's1-b1': 's1-t1',
  's1-b1r': 's1-t1r',
  's1-b2': 's1-t2',
  's1-b2r': 's1-t2r',
  's1-t1': 's1-b1',
  's1-t1r': 's1-b1r',
  's1-t2': 's1-b2',
  's1-t2r': 's1-b2r'
};

export default position => positionMap[position] || position;
