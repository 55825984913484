import * as R from 'ramda';

import getAttachableRootModels from './getAttachableRootModels';

export default (config, models) => {
  const modelNames = [];
  if (!config || !models) return modelNames;
  R.forEach(modelConfig => {
    if (
      modelConfig.is_interactive &&
      !R.isEmpty(getAttachableRootModels(modelConfig, models))
    ) {
      modelNames.push({
        modelName: modelConfig.model_name,
        displayName: modelConfig.display_name
      });
    }
  }, config);
  return modelNames;
};

/*
only ramda funcs

export default (config, models) => {
  const modelNames = [];
  R.forEach(
    modelConfig =>
      R.ifElse(!R.isEmpty(getAttachableRootModels(modelConfig, models)), () =>
        modelNames.push(modelConfig.model_name)
      ),
    config
  );
};
*/
