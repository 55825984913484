import React from 'react';
import List from '@material-ui/core/es/List/List';
import ListItem from '@material-ui/core/es/ListItem/ListItem';
import ListItemAvatar from '@material-ui/core/es/ListItemAvatar/ListItemAvatar';
import Avatar from '@material-ui/core/es/Avatar/Avatar';
import ListItemText from '@material-ui/core/es/ListItemText/ListItemText';
import Page from '../Page';
import styles from './ComponentListTable.module.scss';
import getThumbnailUrl from '../../common/utils/getThumbnailUrl';

const ComponentListTable = ({ models, clutches, screws }) => {
  const renderRow = row => {
    return (
      <ListItem key={row.modelName} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            imgProps={{ className: styles.avatarImg }}
            alt="Remy Sharp"
            src={getThumbnailUrl(row.modelName)}
            className={styles.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={row.displayName}
          secondary={
            <React.Fragment>{`Quantity: ${row.count}`}</React.Fragment>
          }
        />
      </ListItem>
    );
  };

  const reduceRows = (acc, row) => {
    const duplicate = acc.find(accRow => accRow.modelName === row.modelName);
    if (duplicate) {
      duplicate.count += 1;
    } else {
      acc.push(row);
    }
    return acc;
  };

  const modelToRowObject = model => {
    return {
      modelName: model.modelName,
      displayName: model.displayName,
      count: 1
    };
  };

  const transformDoubleSlide = row => {
    if (row.modelName === 'SKLUZAVKA_DOUBLE') {
      row = {
        modelName: 'skluzavka',
        displayName: 'Slide',
        count: 2
      };
    }
    return row;
  };

  const getOverlappingClutchesCount = () => {
    // filtering panels with 2 or more connections
    const panels = models
      .filter(model => model.modelName === 'panel')
      .filter(panel => panel.connections && panel.connections.length > 1);
    const overlappingClutches = positionFirstLetter => {
      return (
        panels
          // filtering panels whose connections positions are on the same side
          .filter(
            panel =>
              panel.connections
                .map(connection => connection.inPosition)
                .filter(position => position.startsWith(positionFirstLetter))
                .length > 1
          )
      );
    };
    // clutches are always pair so multiply by 2
    return (
      (overlappingClutches('t').length + overlappingClutches('b').length) * 2
    );
  };

  const removeDuplicateScrews = row => {
    if (row.modelName === 'sroub_maly') {
      const screwsToRemoveCount = getOverlappingClutchesCount() * 2; // 2x space for long screws
      if (screwsToRemoveCount > 0) {
        // remove extra pair of duplicate small screws and make space for long screws
        row.count -= screwsToRemoveCount + getOverlappingClutchesCount();
        return [row, addLongScrews(screwsToRemoveCount)];
      }
    }
    return row;
  };

  const addLongScrews = count => {
    return {
      modelName: 'sroub_velky',
      displayName: 'Large screw',
      count
    };
  };

  return (
    <Page>
      <div className={styles.componentListWrapper}>
        <div className={styles.staticText}>
          The structure doesn't get saved, don't forget to note your list
          somewhere.
        </div>
        <List className={styles.list}>
          {models
            .map(modelToRowObject)
            .reduce(reduceRows, [])
            .map(transformDoubleSlide)
            .map(renderRow)}
          {clutches
            .map(modelToRowObject)
            .reduce(reduceRows, [])
            .map(renderRow)}
          {screws
            .map(modelToRowObject)
            .reduce(reduceRows, [])
            .flatMap(removeDuplicateScrews)
            .map(renderRow)}
        </List>
      </div>
    </Page>
  );
};

export default ComponentListTable;
