import React from 'react';

import Page from '../Page';

const NotFoundPage = () => (
  <Page>
    <p>Page not found</p>
  </Page>
);

export default NotFoundPage;
