import * as R from 'ramda';

export default (
  connectedModel,
  position,
  clutchName,
  connectedModelScrews,
  ownScrews,
  clutches,
  disabledPositions,
  disableSides
) => {
  if (!connectedModel || !connectedModelScrews || !ownScrews || !clutches)
    return null;
  const getUuid = object => object.uuid;
  return {
    connectedModelUuid: getUuid(connectedModel),
    inPosition: position,
    connectedByClutch: clutchName,
    clutchesUuids: R.map(getUuid, clutches),
    connectedModelScrewsUuids: R.map(getUuid, connectedModelScrews),
    ownScrewsUuids: R.map(getUuid, ownScrews),
    disabledPositions,
    disableSides
  };
};
