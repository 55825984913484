import * as R from 'ramda';

import getRootModelAttachablePositions from './getRootModelAttachablePositions';

export default (modelConfig, models) =>
  R.filter(
    model =>
      !R.isEmpty(getRootModelAttachablePositions(modelConfig, model.positions)),
    models
  );
