import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography
} from '@material-ui/core';

import getThumbnailUrl from '../../common/utils/getThumbnailUrl';

import styles from './ModelItem.module.scss';

// const ModelItem = ({ modelName, addModel, deletePrevious }) => (
const ModelItem = ({ modelName, displayName, selectModel, disabled }) => (
  <Card
    className={styles.wrapper}
    onClick={() => {
      if (!disabled) {
        selectModel();
      }
    }}
  >
    <CardActionArea disabled={disabled} className={styles.button}>
      <CardContent className={styles.content}>
        <img
          src={getThumbnailUrl(modelName)}
          alt={modelName}
          className={styles.img}
        />
        <Typography className={styles.displayName}>{displayName}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

ModelItem.propTypes = {
  modelName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  selectModel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ModelItem;
