import React from 'react';
import PropTypes from 'prop-types';

import styles from './Page.module.scss';

const Page = ({ children, noPadding }) => (
  <div className={`${styles.Page} ${!noPadding ? styles.Padding : ''}`}>
    {children}
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool
};

Page.defaultProps = {
  noPadding: false
};

export default Page;
