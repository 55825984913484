import React from 'react';
import PropTypes from 'prop-types';
// import * as R from 'ramda';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SaveOutlined,
  DeleteForeverOutlined,
  CancelOutlined
} from '@material-ui/icons';
import {
  Collapse,
  Toolbar,
  Divider,
  IconButton,
  Button,
  Hidden
} from '@material-ui/core';

import * as editTypes from '../../constants/EditTypes';

import styles from './ControlsLayout.module.scss';

const ControlsLayout = ({
  editType,
  nextDelete,
  prevDelete,
  nextRoot,
  prevRoot,
  nextPosition,
  prevPosition,
  save,
  cancel,
  handleDelete,
  controlsIn,
  disableRoot,
  disablePosition
}) => (
  <Collapse in={controlsIn}>
    <Toolbar disableGutters className={styles.wrapper}>
      <IconButton
        size="small"
        className={styles.button}
        onClick={editType === editTypes.DELETE ? prevDelete : prevRoot}
        disabled={disableRoot}
      >
        <KeyboardArrowLeft fontSize="small" />
      </IconButton>
      <span className={styles.buttonLabel}>Part</span>
      <IconButton
        size="small"
        className={styles.button}
        onClick={editType === editTypes.DELETE ? nextDelete : nextRoot}
        disabled={disableRoot}
      >
        <KeyboardArrowRight fontSize="small" />
      </IconButton>
      <Divider />
      {editType !== editTypes.DELETE && (
        <React.Fragment>
          <IconButton
            size="small"
            className={styles.button}
            onClick={prevPosition}
            disabled={disablePosition}
          >
            <KeyboardArrowLeft fontSize="small" />
          </IconButton>
          <span className={styles.buttonLabel}>Position</span>
          <IconButton
            size="small"
            className={styles.button}
            onClick={nextPosition}
            disabled={disablePosition}
          >
            <KeyboardArrowRight fontSize="small" />
          </IconButton>
        </React.Fragment>
      )}
      <div className={styles.spacer} />
      {editType === editTypes.ATTACH && (
        <Button size="small" onClick={save}>
          <SaveOutlined
            color="primary"
            fontSize="small"
            className={styles.buttonIcon}
          />
          <Hidden xsDown> Save</Hidden>
        </Button>
      )}
      {editType === editTypes.DELETE && (
        <Button size="small" onClick={handleDelete}>
          <DeleteForeverOutlined
            color="secondary"
            fontSize="small"
            className={styles.buttonIcon}
          />
          <Hidden xsDown>Delete</Hidden>
        </Button>
      )}
      <Button size="small" color="default" onClick={cancel}>
        <CancelOutlined fontSize="small" className={styles.buttonIcon} />
        <Hidden xsDown> Cancel</Hidden>
      </Button>
    </Toolbar>
    <Divider variant="fullWidth" />
  </Collapse>
);

ControlsLayout.propTypes = {
  editType: PropTypes.string,

  nextDelete: PropTypes.func.isRequired,
  prevDelete: PropTypes.func.isRequired,
  nextRoot: PropTypes.func.isRequired,
  prevRoot: PropTypes.func.isRequired,
  nextPosition: PropTypes.func.isRequired,
  prevPosition: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,

  controlsIn: PropTypes.bool,
  disableRoot: PropTypes.bool.isRequired,
  disablePosition: PropTypes.bool.isRequired
};

export default ControlsLayout;
