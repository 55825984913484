import * as THREE from 'three';

import Camera from './camera';
import Scene from './scene';

class Renderer {
  constructor() {
    this._renderer = null;
  }

  isInitialized = () => !!this._renderer;

  init = (offsetWidth, offsetHeight) => {
    try {
      if (this.isInitialized()) return false;
      this._renderer = new THREE.WebGLRenderer({ antialias: true });
      this._renderer.setClearColor(0xffffff);
      this._renderer.setSize(offsetWidth, offsetHeight);
      this._renderer.physicallyCorrectLights = true;
      this._renderer.gammaOutput = true;
      this._renderer.gammaFactor = 2.2;

      return true;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  getRenderer = () => this._renderer;

  getDomElement = () => {
    if (!this.isInitialized()) return null;
    return this._renderer.domElement;
  };

  setSize = (offsetWidth, offsetHeight) => {
    this._renderer.setSize(offsetWidth, offsetHeight);
  };

  render = () => {
    if (!Camera.isInitialized() || !Scene.isInitialized()) return false;

    this._renderer.render(Scene.getScene(), Camera.getCamera());
    return true;
  };
}

const renderer = new Renderer();

export default renderer;
