// COMMON
export const LOAD_CONFIG_REQUEST = 'LOAD_CONFIG_REQUEST';
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_ERROR = 'LOAD_CONFIG_ERROR';

// THREEJS - included in threejs middleware
export const INIT_THREE_CONTAINER = 'INIT_THREE_CONTAINER';
export const UPDATE_THREE_CONTAINER = 'UPDATE_THREE_CONTAINER';
export const RENDER_THREE_CONTAINER = 'RENDER_THREE_CONTAINER';
export const ZOOM_CAMERA = 'ZOOM_CAMERA';
export const UNZOOM_CAMERA = 'UNZOOM_CAMERA';
export const ROTATE_CAMERA = 'ROTATE_CAMERA';
export const ROTATE_CAMERA_REVERSE = 'ROTATE_CAMERA_REVERSE';

// PLAYGROUND - included in threejs middleware
export const ADD_MODEL = 'ADD_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';
export const FETCH_MODEL = 'FETCH_MODEL';
export const FETCHED_MODEL = 'FETCHED_MODEL';
export const ADD_CLUTCH = 'ADD_CLUTCH';
export const DELETE_CLUTCH = 'DELETE_CLUTCH';
export const ADD_SCREW = 'ADD_SCREW';
export const DELETE_SCREW = 'DELETE_SCREW';
export const SELECT_MODEL = 'SELECT_MODEL';
export const UNSELECT_MODEL = 'UNSELECT_MODEL';
export const INIT_PLAYGROUND = 'INIT_PLAYGROUND';

// ADD MODEL
export const SELECT_ATTACHED_MODEL = 'SELECT_ATTACHED_MODEL';
export const START_DELETE_MODEL = 'START_DELETE_MODEL';
export const ADD_ATTACHED_MODEL = 'ADD_ATTACHED_MODEL';
export const SAVE_ATTACHED_MODEL = 'SAVE_ATTACHED_MODEL';
export const CANCEL_ATTACHED_MODEL = 'CANCEL_ATTACHED_MODEL';
export const SET_DELETE_MODEL = 'SET_DELETE_MODEL';
export const SET_ROOT_MODEL = 'SET_ROOT_MODEL';
export const SET_POSITION = 'SET_POSITION';
export const MOVE_ATTACHED_MODEL = 'MOVE_ATTACHED_MODEL';
export const PREVIEW_CLUTCH = 'PREVIEW_CLUTCH';
export const PREVIEW_SCREW = 'PREVIEW_SCREW';

// UNUSED - RESERVED
export const PLACE_MODEL = 'PLACE_MODEL';
