import * as R from 'ramda';

export default (rootModelPosition, rootModelRotation, positionConfig) => {
  let relativePositions = [];
  if (positionConfig.relative_position instanceof Array) {
    relativePositions = R.clone(positionConfig.relative_position);
  } else {
    relativePositions = [positionConfig.relative_position];
  }

  const result = [];
  R.forEach(relativePosition => {
    result.push({
      rootModel: {
        position: rootModelPosition,
        rotation: rootModelRotation
      },
      relativePosition
    });
    if (positionConfig.mirrorX)
      result.push({
        rootModel: {
          position: rootModelPosition,
          rotation: rootModelRotation
        },
        relativePosition: {
          ...relativePosition,
          x: -relativePosition.x,
          ry: relativePosition.ry + 180
        }
      });
    if (positionConfig.mirrorY)
      result.push({
        rootModel: {
          position: rootModelPosition,
          rotation: rootModelRotation
        },
        relativePosition: {
          ...relativePosition,
          y: -relativePosition.y
        }
      });
  }, relativePositions);

  return result;
};
