import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { save as saveAction } from '../../actions/attachedModel';

import { Menu as MenuIcon, ShareOutlined } from '@material-ui/icons';
import CopyRight from '../CopyRight';

import styles from './AppLayout.module.scss';

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const menu = [
  { path: '', displayName: 'Configurator' },
  { path: 'components-list', displayName: 'List' }
];

const drawerContent = (closeDrawer, save) => (
  <div>
    <List className={styles.DrawerList}>
      {menu.map(item => (
        <Link
          key={item.displayName}
          to={`/${item.path}`}
          onClick={closeDrawer}
          className={styles.DrawerListLink}
        >
          <ListItem onClick={save}>
            <ListItemText>{item.displayName}</ListItemText>
          </ListItem>
        </Link>
      ))}
      <Divider variant="middle" />
    </List>
    <CopyRight name="AntonieEmma.cz" />
  </div>
);

class AppLayout extends Component {
  state = { drawerOpen: false };

  openDrawer = () => {
    this.setState({
      drawerOpen: true
    });
  };

  closeDrawer = () => {
    this.setState({
      drawerOpen: false
    });
  };

  fbShare = () => {
    const url = 'https://antonieemma.koala42.com/';
    const winWidth = window.innerWidth / 3;
    const winHeight = window.innerHeight;
    const winTop = winHeight * 0.1;
    const winLeft = (window.innerWidth - winWidth) / 2;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      'sharer',
      `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`
    );
  };

  render() {
    const { children, save } = this.props;
    const { drawerOpen } = this.state;
    return (
      <div className={styles.AppLayout}>
        <AppBar position="relative" className={styles.AppBar}>
          <Toolbar className={styles.ToolBar}>
            <IconButton onClick={this.openDrawer}>
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              open={drawerOpen}
              onOpen={this.openDrawer}
              onClose={this.closeDrawer}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
            >
              {drawerContent(this.closeDrawer, save)}
            </SwipeableDrawer>
            <img
              alt="Antonie a Emma"
              src={`${process.env.PUBLIC_URL}/ae.jpg`}
              className={styles.Logo}
            />
            <IconButton onClick={this.fbShare}>
              <ShareOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={styles.Content}>{children}</div>
      </div>
    );
  }
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  save: bindActionCreators(saveAction, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLayout);
